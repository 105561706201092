.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
:root {
  --jumbotron-padding-y: 3rem;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: #fff;
}
@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: 0.25rem;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.new-card-section {
  background-color: #e9ecef4f;
  text-align: center;
}
.small-text {
  font-size: 1rem !important;
}

.custom-li-style {
  border: "px solid rgba(0,0,0,.125)";
}
.li-label {
  font-weight: bolder;
}
.li-first {
  height: 100%;
}
@media (min-width: 768px) {
  .img-height-equalizer-article {
    height: 600px;
    object-fit: cover;
  }
  .img-height-equalizer {
    height: 400px;
    object-fit: cover;
  }
  .service-card {
    width: 500px;
    margin: auto;
  }
  .books-description-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.overlay-container {
  position: relative;
}
#overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #806b6da6;
  z-index: 2;
}
.o-text {
  color: #fff;
  font-size: 60px;
  line-height: 1.5em;
  text-shadow: 2px 2px 2px #000;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
}

.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 3;
}

.position-main {
  top: 125px;
  position: relative;
}

.card-title {
  font-family: "Oswald";
  font-size: 22px;
}

.card-sub-title {
  font-family: "Lato";
  font-size: 16px;
}

.card-text {
  font-family: "Lato";
  letter-spacing: 0.05em;
}

.action-box {
  display: flex;
  align-items: center;
}
.pt8 {
  padding-top: 8px;
}

.action-btn {
  background-color: #343a40;
  flex-grow: 2;
}
button {
  transition: all 0.2s ease-in-out;
}

button:not(:disabled):active {
  transform: translateY(1px);
}
.distribution__links_grid {
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.distribution__links[data-v-bb7d009c] {
  display: flex;
  list-style: none;
}

.distribution__links-el:first-child {
  display: block;
}
.link {
  height: 43px;
  outline: none;
  display: flex;
  padding: 7px 7px;
  padding-left: 8px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #000;
  border: 1px solid #a6a6a6;
}
.link__icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.link__content {
  display: flex;
  flex-direction: column;
}
.link__content > span {
  color: #fff;
  line-height: 1;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 1px;
}

.link__content > strong {
  color: #fff;
  line-height: 1;
  font-size: 14px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 480px) {
  .distribution__links_grid {
    grid-template-columns: 1fr;
  }
}
